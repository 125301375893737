<template>
  <v-footer class="py-0" app color="#463e3b" dark height="40" elevation="4">
    <v-row align="center" dense>
      <v-col class="text-left">
        <v-btn
          icon
          class="white--text"
          href="https://web.facebook.com/gofloridatalca/?_rdc=1&_rdr"
          target="_blank"
        >
          <v-icon>mdi-facebook</v-icon>
        </v-btn>

        <v-btn
          icon
          class="white--text"
          href="https://www.instagram.com/goflorida.cl/?hl=es"
          target="_blank"
        >
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
        <v-btn
          icon
          class="white--text"
          href="https://goflorida.cl/"
          target="_blank"
        >
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <span class="white--text">&copy; 2025 Mall Florida Go</span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>
