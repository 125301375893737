<template>
  <v-container fluid class="main-container">
    <!-- Primera fila: Título y subtítulo -->
    <v-row dense class="title-row">
      <v-col md="12" cols="12" class="d-flex flex-column align-center">
        <h1 class="main-title">Sistema de Registro de Ventas</h1>
        <p class="sub-title">
          Gestiona las ventas y operaciones de tu local de manera rápida,
          precisa y eficiente.
        </p>
      </v-col>
    </v-row>

    <!-- Segunda fila: Video -->
    <v-row dense class="video-row">
      <v-col md="12" cols="12" class="d-flex justify-center">
        <v-container class="d-flex justify-center align-center video-container">
          <v-responsive>
            <iframe
              :src="videoUrl"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="video-iframe"
            ></iframe>
          </v-responsive>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "YouTubeVideo",
  data() {
    return {
      videoId: "RSC8PbEvVGQ", // Reemplaza con el ID del video de YouTube
    };
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
  },
};
</script>

<style scoped>
/* Contenedor principal */
.main-container {
  padding: 0; /* Elimina rellenos internos del contenedor */
}

/* Título y subtítulo */
.title-row {
  margin: 0; /* Elimina márgenes entre filas */
  padding: 1rem 0; /* Espaciado interno personalizado */
}

/* Fila del video */
.video-row {
  margin: 0; /* Elimina márgenes entre filas */
  padding: 0; /* Sin espaciado adicional */
}

/* Contenedor del video */
.video-container {
  padding: 0; /* Sin relleno interno */
  margin: 0 auto; /* Centra el video horizontalmente */
  height: 60vh; /* Ajusta el tamaño del contenedor al 60% del viewport */
}

/* Iframe del video */
.video-iframe {
  width: 100%;
  height: 100%;
  border: none; /* Elimina bordes para un diseño más limpio */
}

/* Responsivo: Tamaño máximo del video */
.v-responsive {
  max-width: 1280px; /* Limita el ancho máximo */
  height: 100%; /* Asegura que ocupe el 100% del contenedor */
  margin: auto; /* Centra el video horizontalmente */
}

/* Estilo del título */
.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fd5c01;
  text-align: center;
  margin-bottom: 0.5rem;
}

/* Estilo del subtítulo */
.sub-title {
  font-size: 1.2rem;
  color: #555555;
  text-align: center;
  margin-bottom: 1rem;
}
</style>
